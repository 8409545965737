export const en = {
    common: {
        success: "Success!",
        next: 'Next',
        discount: 'Discount',
        pay: 'Pay',
        buy: 'Buy',
        price: 'Price',
        schedule: 'Schedule',
        orderPaid: 'This order has already been paid',
        certificate: 'Gift Card',
        promocode: 'Promo Code',
        bookingClosed: 'Booking closed',
        chooseOtherSlot: 'Choose other slot',
        placesEnded: 'Unfortunately places ended, but you can pick other product',
        placesWithDiscountEnded1: 'You want to buy more places ({{places}}) at a discount than there are left ({{remainder}}). To make it work, reduce the number of seats. But if there is no other way, write to TG',
        placesWithDiscountEnded2: '- We\'ll come up with something',
        toMain: 'To the main',
        pluralizePlaces: 'person',
        freeSits: 'available seats',
        member: 'Member',
        canEditData: 'You can edit data in the ',
        personalAccount: 'personal account',
        noProductsAvailable: 'It seems there are no products available...',
        goToCalendar: 'Go to calendar',
        orderDeletedDescription: 'Time flew by quickly — the places for this adventure have recently ended. But we definitely have something on the same dates or in a similar water area.',
        orderDeletedDescription2: 'The team of participants of this adventure has already been formed — we have sold all the seats. We will be glad to see you on other routes — we definitely have something on these dates or in a similar water area.',
        layout: {
            pickDate: 'Pick a date',
            members: 'Members',
            confirmation: 'Confirmation',
            payment: 'Payment',
            finish: 'Finish',
            payExtraCardDesc: 'After clicking, the system will transfer you to the payment site',
        },
        fields: {
            "phone": {
                "label": "Phone number",
                "validation": {
                    "required": "Field is mandatory",
                    "format": "Enter the correct phone number"
                }
            },
            "phoneCode": {
                "label": "Enter the code",
                "validation": {
                    "required": "Field is mandatory",
                    "invalidCode": "Invalid code",
                    "unknownError": "Something went wrong",
                    "phoneTaken": "This phone is already taken"
                }
            },
            "password": {
                "label": "Password",
                "signUpLabel": "Create a password",
                "placeholder": "Better with numbers and symbols",
                "strength": {
                    "easy": "Hardly safe",
                    "medium": "Middle-level safe",
                    "hard": "Safe enough"
                },
                "validation": {
                    "passError": "Oops! We don't have this login-password pair",
                    "required": "Field is mandatory",
                    "minLength": "Minimum length is 5 symbols",
                    "pattern": "Invalid symbols (only digits, latin letters and symbols ! @ # % are available)"
                }
            },
            "captcha": {
                "validation": {
                    "wrongCaptcha": "Oh! You didn't pass the captcha!"
                }
            },
            "email": {
                "label": "Email",
                "placeholder": "For receipts and confirmations",
                "validation": {
                    "emailTaken": "We already have this email",
                    "regEmailTaken": "We already have this account",
                    "required": "Field is mandatory",
                    "pattern": "Seems like a mistake in email"
                }
            },
            "consent": {
                "label": "I agree with",
                "linkCaption": "personal data processing",
                "validation": {
                    "consent": "Please, accept personal data processing agreement"
                }
            },
            "firstName": {
                "label": "Name",
                "placeholder": "What's your name?",
                "validation": {
                    "required": "Field is mandatory",
                    "pattern": "Seems like a mistake in name"
                }
            },
            "lastName": {
                "label": "Surname",
                "validation": {
                    "required": "Field is mandatory"
                }
            }
        },
        buttons: {
            "switch": "Русский",
            "getCode": "Get the code",
            "signUp": "Sign up",
            "loginViaEmail": "Log in via email",
            "loginViaPhone": "Log in via phone",
            "send": "Send",
            "getNewCode": "Get new code",
            "logIn": "Log in",
            "recoverPassword": "Recover password"
        },
        logIn: {
            "phone": {
                "title": "Log in / sign up",
                "codeSent": "Code has been sent to",
                "requestCodeText": "Resend code in"
            },
            "password": {
                "title": "Log in to continue"
            }
        },
        logInVia: "Log in via",
        helpers: {
            "or": "or",
            "change": "change"
        },
        forgotPassword: {
            "linkCaption": "Forgot your password?",
            "title": "Enter an email linked to your account. We'll send you a password restoration link",
            "backToLogIn": "Back to log in",
            "accountNotExists": "Account does not exist",
            "signUpSuggestion": "Create one",
            "restoreSuccess": "Password restoration link has been sent. If you don't receive letter in 5 minutes, check the Spam folder"
        },
        signUp: {
            "title": "Create an account",
            "gotAnAccount": "Got an account?",
            "logIn": "Log in",
            "signUpVia": "Sign up via"
        },
        social: {
            "fallbackText": "We sent the correct login-password keys pair to your email. If you don't receive this, contact our support",
            "successfulAuth": "Please, enter your name and surname so we could know how to call you properly"
        },
        redirect: "Redirecting",
        file: 'File',
        telegram: 'Telegram'
    },
    inputs: {
        required: 'Field is required',
        pattern: 'Field must have uppercase letters',
        cyrillicAndCapitalError: 'Field must have uppercase letters and only Cyrillic',
        maxLengthError: 'Max 10 symbols',
        onlyCyrillicError: 'Use Cyrillic only',
        onlyLatinError: 'Use only Latin',
        formatError: 'Use format: ',
        errorDateFormat: 'DD.MM.YYYY',
        firstName: {
            label: 'First name',
            placeholder: 'AHMED'
        },
        lastName: {
            label: 'Last name',
            placeholder: 'AHMED'
        },
        phone: {
            label: 'Phone number',
            pattern: 'Use +7 (XXX) XXX-XX-XX format',
            wrong: 'Wrong phone number',
        },
        sailingExperience: {
            label: 'Level of experience *your experience may be approximate',
            required: 'Pick your sailing experience',
            defaultValue: 'Your experience:',
            '0/7': 'Beginner',
            '1/7': 'Experienced',
            '2/7': 'Advanced',
            '3/7': 'I’m on all courses and I can dock',
            '4/7': 'perfect technique in detail',
            '5/7': 'the wind and the situation on the water',
            '6/7': 'preparing to win the race',
            '7/7': 'sailing master, learning strategy and tactics',
            'travel0/7': 'Beginner',
            'travel1/7': 'Experienced',
            'travel2/7': 'Advanced',
            'travel3/7': 'was at the regatta ',
            'travel4/7': 'chartman',
        },
        email: {
            label: 'Email',
            pattern: 'Enter an existing email address'
        },
        birthdate: {
            label: 'Date of birth'
        },
        enterPromocode: {
            placeholderPromo: 'Enter a promo code',
            placeholderCert: 'Enter a Gift Card',
            label: 'Promo code / Gift Card',
            placeholder: 'Enter a promo code or Gift Card',
            submitButton: 'Apply',
            cancelButton: 'Cancel',
            wrongPromoOrCert: 'Promocode or certificate is invalid',
            wrongPromo: 'Promocode is invalid',
            wrongCert: 'Certificate is invalid',
            corpCertApplied: 'A corporate certificate has been applied.',
            corpCertAppliedDesc: 'There are no other discounts for corporate clients.'
        },
        comment: {
            label: 'Comment',
            placeholder: 'Please include any additional information about yourself or special requests for the trip in the comments section',
            maxLength: 'Comment is too long, max length is 300 symbols',
            travelPlaceholder: 'Special preferences',
        },
        consents: {
            publicOffer: 'General Terms and Conditions',
            publicOfferNote: '',
            publicOfferError: 'Please confirm the public offer',
            validData: 'Agree to the processing of personal data',
            validDataError: 'Please confirm the processing of personal data',
            returnPolicy: 'Agree with the return policy',
            returnPolicyNote: '',
            returnPolicyTrain: '',
            returnPolicyError: 'Please confirm the return policy',
            checkAge: 'Confirm that all participants are over {{checkAgeValue}} old',
            checkAge2: '',
            pleaseCheckAgeValue: 'Please confirm that all participants are over {{checkAgeValue}} years old. If they are not, we invite them to visit our ',
            pleaseCheckAgeValueEN: 'Please confirm that all participants are over {{checkAgeValue}} years old.',
            ifHaveNoAge: '',
            childrenClub: 'Kids club',
            checkAgeSecondPart: ', where there are special activities for children from 7 years old.',
        },
        city: {
            label: 'City',
            placeholder: 'Enter a city',
        },
        region: {
            label: 'Region',
            placeholder: 'Enter a region',
        },
        telegram: {
            pattern: 'Type in Latin'
        },
        passportDate: {
            error: "The passport is invalid while travelling, please enter passport number 0000",
        },
        gender: {
            label: 'Gender',
            error: 'Enter gender',
            female: 'F',
            male: 'M',
            none: '-',
        },
        passportDateForeign: {
            error: 'The passport is invalid while travelling, please enter passport number 0000'
        },
        shirtSize: {
            required: 'Select T-shirt size',
        },
        flat: {
            label: 'Apartment number',
            error: 'For apartment building fill in apartment number',
            ownHouse: 'Live in a private house'
        },
        street: 'Street',
        house: 'House',
        address: {
            label: 'Buyer’s residence address*',
            validate: 'Type country and city',
            fullAddressValidate: 'Type full address (country, city, street, apartment)',
            placeholder: 'Residential address',
        },
        provideAddress: 'Provide the exact address',
        passportFile: 'Id or Passport'
    },
    discounts: {
        coachless: 'without instructor discount',
        fullBook: 'full book discount',
        subscription: 'Sailing Pass discount',
        people: 'Discount from {{peopleCount}} people',
        peopleFull: 'Discount for booking from {{peopleCount}} people',
        fullBookInstructor: 'Boat without instructor',
        discountLess: 'Unfortunately, the amount of the discount will decrease:',
        discountEqual: 'The amount of the discount has not changed:',
        discountRise: 'Congratulations, the discount will increase:',
        discountCurrentOn: 'Your current Sailing Pass to',
        discountWillUpOn: 'will be topped up on',
        discountOnYachtLuch: 'Sale for Sailing Experience, Saturday Race, Coastal race, Basics of Yachting, Basics of Racing, Sailing Upskill',
        discountOnIndividualLuch: 'Sale for Coastal Race, Special trainings, Basics of sailing —',
        discountOnRace: 'Sale for Open Sailing Series and Wind Rises Cup'
    },
    sport: {
        pluralizeBoats: 'boat',
        schedule: {
            selectors: {
                pickBase: 'Pick base',
                base: 'Base',
                pickBoat: 'Boat class',
                boat: 'Boat',
                pickDate: 'Pick a date',
                date: 'Date',
                pickTime: 'Pick time',
                time: 'Time',
                anyInstructor: 'Any',
                instructorWarning: 'We\'ll do our best to accommodate your requests, but we can\'t always guarantee that a specific instructor will be available',
                availableSits: 'available seats',
                pickInstructor: 'Pick instructor',
                bookWholeBoat: 'Full boat booking',
                noEnoughSeatsToFull: 'No enough seats to full',
                noVacantSeats: 'The selected event has run out of seats, you will be redirected',
                bookWithoutInstructor: 'Book without instructor',
                withoutInstructor: 'Without instructor',
                firstBookingLimitWarning: "It's nice to see that you have a big, cool group of people together - maybe it's time to organize a training session or race just for 'your own'? Email us at ",
                secondBookingLimitWarning: "It seems like you are planning a big event! For companies of 20 people or more, we are ready to organize personal training or regattas. Email us at ",
                BookingLimitEmail: 'event@silavetra.team',
                BookingLimitNumber: '+74951081503',
                makeItHappen: ", we'll make it happen!",
                orCall: 'or call ',
            }
        },
        membersScreen: {
            dontFillOthers: 'Don\'t fill others members',
            startDate: 'Event start date and time',
            subscriptionPayment: 'payment',
            subscriptionBalance: 'Subscription balance:',
            haveNoSubscription: 'You have no Sailing pass.',
            pay: 'Sailing pass',
        },
        noPlaces: 'No Places',
    },
    travel: {
        regataRegister: 'You’re just a few steps away from booking your trip!',
        pickMembersCount: 'Select number of participants:',
        noPlaces: 'No places',
        title: 'Travel',
        startPlace: 'Start place',
        startDate: 'Start date',
        '20%': 'Pay 20%',
        '20%_extra': ' now and pay the rest of the payment a month before the trip',
        '20%_extra_adventures': ' now and pay the rest of the payment 45 days before the trip',
        '50%': 'Pay 50%',
        '50%_extra': ' now and pay the rest of the payment a month before the trip',
        '50%_extra_adventures': ' now and pay the rest of the payment 45 days before the trip',
        '100%': 'Pay 100% now',
        'practice100%': 'Pay 100% now',
        arrivalDate: 'Travel date',
        include: 'Included:',
        notInclude: 'Not included:',
        freeSits: 'Available seats',
        firstName: {
            label: 'First name (EN)',
            placeholder: 'Name (EN)'
        },
        lastName: {
            label: 'Last name (EN)',
            placeholder: 'Last name (EN)'
        },
    },
    paymentTimer: {
        expired: 'The time to pay for your order has expired, you need to go one step back',
        haveTime: 'You have {{time}} to pay'
    },
    practice: {
        label: 'Practice',
        register: 'You’re just a few steps from registering for the practice',
        '50%_extra': ', and the rest - no later than a month before the trip. Please note that when paying in installments, the cost of the practice will increase by 5%.',
    },
    errors: {
        placeLack: 'Not enough free places',
        someoneAlready: 'Oops... Someone has already booked. Now there are no more places. Try to choose another time.',
        expired: 'Oops... Time is no longer available for booking. Try another.',
        coachNotAvailable: 'This instructor is out of places.',
        phoneAlreadyUses: 'The phone is already busy',
    },
    productContainer: {
        headerLabel: 'booking'
    },
    headerMenu: {
        leave: 'Exit',
        profile: 'Profile'
    },
    school: {
        pickGroup: 'Pick group',
        group: 'Group',
        place: 'Place',
        address: 'Address',
        freePlaces: 'Available seats',
        lecture: 'Lecture',
        '0/7': 'Beginner',
        '1/7': 'Experienced',
        '2/7': 'Advanced',
        '3/7': 'went himself as a helmsman several times',
        '4/7': 'went a lot as a helmsman, have experience, need rights',
        overbookError: 'Unfortunately, all places have run out, but you can select other available events below:',
        expiredError: 'Unfortunately, the start date of the lecture has already passed, but you can select other available events below:',
        noGroupsError: 'Unfortunately, no classes available, but you can select other available events below:',
        showAllLectures: 'Show all lectures',
        showAllTheories: 'Show all theories',
    },
    bookingCard: {
        direction: 'Direction',
        temperature: 'Temperature',
        air: 'Air',
        water: 'Water',
        waterTime: 'Time on the water per day',
        discount: 'Discount',
        members: 'Members',
        price: 'Price',
        free: 'FREE',
        from: 'from',
        to: 'to',
        outstandingPay: 'Outstanding payment',
        difficultyLevel: 'Difficulty level',
        event: 'Event',
        duration: 'Duration',
        max: 'max',
        instructor: 'Instructor',
        teacher: 'Teacher',
        name: 'Name',
        startTimeAt: 'START TIME',
        place: 'Place',
        h: 'H',
        m: 'M',
        title: 'Title',
        lecturer: 'Lecturer',
    },
    helpers: {
        anHour: 'hour',
        hours: 'hours',
        hours2: 'hours',
    },
    final: {
        processing: 'Your payment is still being processed. Status will be updated soon',
        eventSuccess: 'You have successfully paid for the event! Don\'t forget to prepare everything you will need by {{date}}!',
        tableEventSuccess: 'You have successfully made your reservation! To make your adventure happen at {{date}}, please contact the administrator for payment. You can contact us by phone +971 58 106 50 26',
        materials: 'In the meantime, read our article. Perhaps it will help you prepare for this event:',
        firstTrains: 'First sailing practice',
        takeOnTrain: 'What to bring to training',
        luchClothes: 'How to dress for «Rays» and «Lasers»',
        gloves: 'Yachting Gloves: Battle Test',
        shoe: 'Experiment: which shoe slides less',
        onTheMain: 'back to main',
        inCalendar: 'Add to calendar',
        inWhatCalendar: 'Which calendar add the event to?',
        bookMore: 'Book more',
        regattaSuccess: 'Great, you\'re all set for the regatta! Make sure to get everything you need ready by {{date}}.',
        questions: 'If you have any questions, please call us on ',
        orContactEmail: ' or shoot us an email at ',
        infoOnEmail: 'In the letter we will send detailed information about the journey and how to prepare for it.',
        waitEmail: 'Expect a letter to the address you provided when registering.',
        paymentSuccess: ' is successfully adopted.',
        paymentProcessing: ' is in processing. Its status will be updated later.',
        yourPayment: 'Your payment for booking ',
        from: ' from ',
        to: ' to ',
        on: ' to ',
        soonWillEmail: 'We\'ll soon send an email to the address you provided at booking with all the details about the upcoming course',
        before: 'Before practice, we recommend to check out some articles in our online magazine:',
        firstTravel: 'Useful instructions for your first yachting trip',
        advices: 'Tips&trics by Wind Rises students',
        aboutYachtLicence: 'Everything you need to know about yachting licenses',
        ifHaveQuestions: 'If you have any questions, please call us on +971 58 106 50 26 or email us at ',
        thanksForBook: 'Thanks for the booking! Your payment {{places}} {{accusativePlaces}} on {{orderName}}',
        practiceFrom: ' from ',
        inProcess: ' is being process.',
        successPayment: 'Congratulations! You\'ve successfully paid for {{places}} {{dativePlaces}} from',
        howGetLicense: 'How to get your license and become a captain',
        youngYachtMen: 'Young yachtsmen on how to become a captain',
        online: ' online ',
        offline: ' offline ',
        schoolTo: ' to ',
        successTheory: 'Congratulations! You\'ve successfully paid for the theory course',
        beingProcessing: 'being processing.',
        successBookLecture: 'Congratulations! You have successfully registered for lecture',
        soonWillEmailLecture: 'We will send an email with all the details of the upcoming lecture to the address you provided at the time of booking.',
        successPayLecture: 'Congratulations! You\'ve successfully paid for lecture attendance',
        joinTelegram: 'Don\'t forget to join the Telegram group and check the educational platform!',
        yourTG: 'Telegram channel for your group',
        learningPlatform: 'Educational platform',
        code: 'Login code',
    },
    feedback: {
        poorly: 'Poorly',
        unsatisfactory: 'Unsatisfactory',
        satisfactorily: 'Satisfactorily',
        good: 'Good',
        perfectly: 'Perfectly',
        ratePlease: 'Please rate our booking system!',
        commentPlaceholder: 'We look forward to receiving your comments to help us make our booking system even better!',
        thanks: 'Thanks for review!',
        send: 'Send',
    },
    profile: {
        certificatesSubscriptions: {
            blockTitle: 'Gift Cards and Sailing Passes',
            certificateLabel: 'Check the Gift Card',
            certificatePlaceholder: 'Enter the Gift Card code',
            certificateNotFound: 'The gift card does not exist',
            certificateNotAvailable: 'The gift card is not active',
            certificateBalance: 'Gift Card balance',
            certificateApplies: 'Applies to',
            certificateCountry: 'Country of application',
            certificateAvailableTo: 'Valid until',
            check: 'Check',
            subscription: 'Sailing Pass',
            subscriptionBalance: 'Sailing Pass balance',
            subscriptionDiscount: 'Sale for Sailing Experience, Saturday Race, Coastal race, Basics of Yachting, Basics of Racing, Sailing Upskill',
            subscriptionIndividualDiscount: 'Sale for Coastal Race, Special trainings, Basics of sailing',
            subscriptionDiscountRace: 'Sale for Open Sailing Series and Wind Rises Cup',
            subscriptionEmpty: 'You don’t have an active Sailing Pass',
            buy: 'Buy'
        },
        data: {
            phoneTaken: 'This number is already occupied.',
            blockTitle: 'Personal data',
            nameLabel: 'First name',
            namePlaceholder: 'Alex',
            surnameLabel: 'Last name',
            surnamePlaceholder: 'Thomson',
            phoneLabel: 'Phone number',
            emailLabel: 'Email',
            experienceLabel: 'Sailing experience',
            experienceDefaultLabel: 'Specify your experience',
            experience0: 'Beginner',
            experience1: 'Experienced',
            experience2: 'Advanced',
            experience3: 'I can walk against and in the wind',
            experience4: 'I arm the boat myself and knit knots',
            experience5: 'I moor myself',
            experience6: 'I pass all sections of the distance',
            experience7: 'I participate in races as a helmsman',
            success: 'Data successfully updated',
            save: 'Save',
            passportInternational: 'ID or Passport',
            for: 'for',
            travel: 'travel',
            enNameLabel: 'First name (Latin)',
            enSurnameLabel: 'Last name (Latin)',
            passport: 'Id or passport number',
            passportFiles: 'Upload a photo id or passport',
            citizenship: 'Citizenship',
            citizenshipPlaceholder: 'Russia',
            unsubscribed_news: 'Unsubscribe from news'
        },
        exit: 'Exit',
        twofa: {
          modal: 'Confirm action',
          placeholder: 'Code from email',
          submit: 'Submit',
          resendAgain: 'Send code again via: ',
          resendSubmit: 'Send new code',
        }
    },
    logout: 'Log out of your account',
    notFound: {
        pageNotFound: 'Page not found',
        backToMain: 'Go back to the main page'
    },
    newPassword: {
        saveAndContinue: 'Save and continue',
        description: 'We have reset your old password. Come up with a new one',
        label: 'New password',
        placeholder: 'With numbers, or better with symbols'
    },
    tablet: {
        walk: 'Training',
        sport: 'Races'
    },
    api: {
        usePhoneLogin: {
            unknownError: 'Something went wrong',
            phoneLimit: 'Exceeded SMS limit to your phone number',
        },
        useApi: {
            notFound: 'Not found'
        }
    },
    constructor: {
        redirectText: 'After authorization, we will redirect you to the corporate page'
    },
    agreements: {
      personalData: 'https://www.dubai.windrises.com/legal/privacy-policy'
    },
    certificate: {
      headerLabel: 'Booking',
      label: 'Wind rises gift card',
      ownerName: 'Owner name',
      country: 'Country',
      extra: 'Please note that it is an open date valid for 1 year from the moment of purchase. Gift Card is non-exchangeable',
      price: 'Price',
      wish: 'Wish',
      code: 'Gift Card code',
      productType: 'Product type',
      form: {
        inputName: 'Enter name of',
        inputNamePlaceholder: 'First name',
        inputSurname: 'Enter last name of',
        inputSurnamePlaceholder: 'Last name',
        recipients: 'the recipient',
        sender: 'the sender\'s',
        inputWish: 'Specify wishes',
        inputWishPlaceholder: 'Write a wish...',
        inputWishMaxLength: 'Max. number of characters: 45',
        inputEmail: 'Enter email of',
        inputCurrency: 'Currency',
        inputProductType: 'Select product type',
        inputCountry: 'Select country of purchase',
        inputNominal: 'Specify the value of the Gift Card',
        inputSum: 'Specify the amount',
        inputSendToEmail: 'Send to recipient via email',
        submit: 'Buy',
        description: 'There are only a couple steps left to sign up for a Gift Card. After payment, you will receive access to the Gift Card itself electronically and a unique code to book your Sailing Experience or Race.',
        inputPhone: 'Enter your phone number',
        inputSendersEmail: 'Enter the sender\'s e-mail address',
        success: {
          title: 'Yay, congratulations! We\'ve sent the Gift card to your email — make your friends and family happy!',
          certNumber: 'Your Gift Card code',
          downloadButton: 'Download gift card',
          onTheMain: 'To home page',
          orderOneMore: 'Check out another one',
        }
      },
    },
    subscriptions: {
      headerTitle: 'Sailing pass',
      countryOfUsage: 'Country of application',
      ownerEmail: 'Owner e-mail',
      payWithCert: 'Pay by gift card',
      enterCert: 'Enter Gift Card',
      balance: 'Balance',
      debit: 'Debit',
      publicOffer: 'https://www.dubai.windrises.com/legal/general-terms-and-conditions',
      title: 'There are only a couple of steps left before you sign up for a sailing pass. After payment you will be able to use your sailing pass to pay for training and racing at our branch.',
      nominal: 'Sailing Pass nominal -',
      discountGroupLabel: 'Sailing experience',
      discountKilLabel: 'Races for beginners',
      discountRaceLabel: 'Races',
      discountIndividualLabel: 'Basics of YACHTING',
      name: 'Name',
      andSurname: 'and surname',
      price: 'Amount',
      amountOfAbonement: 'Specify the cost of the sailing pass',
      certificateDiscount: 'gift card discount',
      success: {
        title: 'Congratulations! Your Sailing Pass is complete. Enjoy your experience!',
        howToUse: 'How to use the Sailing Pass:',
        step1: '1. Log in to the account on our website from which you purchased the Sailing Pass.',
        step2: '2. Select the product you want to use.',
        step3: '3. At checkout, select the "Pay with Sailing Pass" option.',
        step4: '4. Done. The discount applied will depend on the price of your Sailing Pass.',
        step5: '5. You can view the balance of your Sailing Pass in your personal account or contact a member of our team.',
        redirect: 'You will be redirected to product checkout via:',
        redirectSeconds: 'seconds',
        onMain: 'To home page',
        toBooking: 'To booking page'
      }
    },
    generic: {
      dropzone: {
        dropFile: 'Drop file (or click)',
        uploadFile: "Upload file",
        formatTooLarge: 'Format too large',
        formatNotAccepted: 'Format is not accepted',
        invalidType: 'Invalid file type',
        delete: 'Remove'
      }
    },
    paymentPay: {
      methods: {
        card: 'Pay by bank card',
        sub: 'Pay by Sailing Pass',
        cert: 'Pay with a Gift Card',
        cash: 'Pay in cash',
      },
      remainder: {
        sub: 'Remainder after booking',
        subToPayment: 'Remainder',
        cert: 'Remainder',
      },
      selectMethod: 'Choosing a payment method',
      noneActiveSub: 'You don’t have active Sailing Pass',
      notEnoughBonus: 'not enough points',
      bonusCount: 'bonuses',
      notEnoughMoney: 'additional fee',
      promoDiscount: 'Promo code discount',
      certBalance: 'Gift Card balance',
      payExtra: 'Pay extra',
      payByCard: 'by card',
      payByCash: 'by cash',
      wrongPromo: 'Wrong promo code number',
      subscription: 'Sailing Pass',
    },
    orders: {
      statistic: {
        slots: 'Trainings',
        arrivals: 'Adventures and Practices',
        groups: 'Groups',
        count: 'Orders count'
      }
    }
}